import { render, staticRenderFns } from "./ParceriaCupomHistoricoComponent.vue?vue&type=template&id=71148fb1&scoped=true&"
import script from "./ParceriaCupomHistoricoComponent.vue?vue&type=script&lang=ts&"
export * from "./ParceriaCupomHistoricoComponent.vue?vue&type=script&lang=ts&"
import style0 from "./ParceriaCupomHistoricoComponent.vue?vue&type=style&index=0&id=71148fb1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71148fb1",
  null
  
)

export default component.exports