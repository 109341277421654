import { __decorate } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';
import { cdaStore, parceriaCupomHistoricoStore, mainStore, } from '@/utils/store-accessor';
import ClickableCard from '@/components/ClickableCard.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
import PerdaComercialComponent from '@/components/PerdaComercialComponent.vue';
import { newParceriaCupomHistorico, } from '@/interfaces/parceriaCupomHistorico';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let ParceriaCupomHistoricoComponent = class ParceriaCupomHistoricoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Data do contato', value: 'created_at' },
            { text: 'Status', value: 'status_id' },
            { text: 'Contato por', value: 'cda_nome' },
            { text: 'Observações', value: 'observacao' },
        ];
        this.headersNumerosRepetidos = [
            { text: 'Nome', value: 'nome' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Último contato', value: 'ultimo_contato' },
            { text: 'Parceria', value: 'parceria_nome' },
        ];
        this.calledCupom = { observacao: '' };
        this.cupomLigacaoStatus = 0;
        this.items = [];
        this.loading = false;
        this.observacoesLigacao = '';
        this.saveLoading = false;
        this.scheduleDatePicker = null;
        this.scheduleTime = '';
        this.showObservacoes = false;
        this.motivoPerdaId = null;
    }
    async submit() {
        if (this.saveLoading) {
            return;
        }
        let isValid = true;
        this.saveLoading = true;
        if (this.$refs.agendamentoObserver) {
            isValid = await this.$refs.agendamentoObserver.validate();
        }
        if (!isValid) {
            this.saveLoading = false;
            return;
        }
        let agendamento = this.scheduleDatePicker + ' ' + this.scheduleTime;
        if (!(this.scheduleDatePicker && this.scheduleTime)) {
            agendamento = null;
        }
        await parceriaCupomHistoricoStore.createParceriaCupomHistorico({
            parceria_cupom_id: this.propParceriaCupom.id,
            status_id: this.cupomLigacaoStatus,
            agendamento,
            observacao: this.observacoesLigacao,
        });
        this.items =
            await parceriaCupomHistoricoStore.getParceriaCupomHistoricoByParceriaCupomId(this.propParceriaCupom.id);
        this.loading = true;
        await cdaStore.getCupons();
        this.cupomLigacaoStatus = -1;
        this.observacoesLigacao = '';
        this.scheduleDatePicker = null;
        this.scheduleTime = '';
        this.loading = false;
        this.saveLoading = false;
    }
    async cupomLigar() {
        this.cupomLigacaoStatus = 1;
        this.showObservacoes = true;
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = `tel:${this.propParceriaCupom.telefone}`;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
    }
    createParceriaCupomHistorico(status, agendamento, parceriaId, agendar) {
        const historicoCreate = newParceriaCupomHistorico();
        historicoCreate.company_id = mainStore.userProfile.company.id;
        historicoCreate.cda_id = mainStore.userProfile.id;
        const today = new Date();
        today.setHours(today.getHours() - 3);
        if (agendar) {
            if (agendamento) {
                historicoCreate.agendamento = agendamento;
            }
            else {
                historicoCreate.agendamento = new Date(today).toISOString();
            }
        }
        else {
            historicoCreate.agendamento = null;
        }
        historicoCreate.status_id = status;
        historicoCreate.parceria_cupom_id = parceriaId;
        if (this.calledCupom.observacao) {
            historicoCreate.observacao = this.calledCupom.observacao;
        }
        return historicoCreate;
    }
    numeroRepetido() {
        return _.filter(cdaStore.cupons, (item) => {
            // @ts-ignore
            return (item.telefone === this.propParceriaCupom.telefone &&
                item.id !== this.propParceriaCupom.id);
        });
    }
    async mounted() {
        this.loading = true;
        this.items =
            await parceriaCupomHistoricoStore.getParceriaCupomHistoricoByParceriaCupomId(this.propParceriaCupom.id);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Object })
], ParceriaCupomHistoricoComponent.prototype, "propParceriaCupom", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ParceriaCupomHistoricoComponent.prototype, "propPermiteLigar", void 0);
ParceriaCupomHistoricoComponent = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            ClickableCard,
            DateFieldComponent,
            PerdaComercialComponent,
        },
    })
], ParceriaCupomHistoricoComponent);
export default ParceriaCupomHistoricoComponent;
